import React from 'react'
import { Helmet } from 'react-helmet'

export default function HtmlHeader() {
    return <Helmet>
        <meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width' />
        <link rel='stylesheet' href='https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap' />
        <link rel="preconnect" href="https://fonts.gstatic.com"/>
        <link href="https://fonts.googleapis.com/css2?family=Fira+Sans:wght@500&display=swap" rel="stylesheet"/>
        {/* <script src='/modernizr.min.js' /> */}
    </Helmet>
}