import { graphql, useStaticQuery } from 'gatsby'
import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import Bar from './components/bar'

interface ContextValue {
    _setTitle: React.Dispatch<React.SetStateAction<string | undefined>>
}

const Context = createContext<ContextValue>({
    _setTitle(v) { console.log(v) }
})

function useValue() {
    return useContext(Context)
}

export function useSetTitle(title?: string) {
    const {_setTitle} = useValue()

    useEffect(() => {
        _setTitle(title)
    }, [_setTitle, title])
}

export function Provider({children}: {children: ReactNode}) {
    const { site } = useStaticQuery(graphql`query NavigationBar {
        site {
            siteMetadata { title }
        }
    }`)

    const [title, _setTitle] = useState<string | undefined>(undefined)

    const contextValue = useMemo<ContextValue>(() => ({
        _setTitle
    }), [])

    return (
    <>
        <Helmet title={[title ?? '', site.siteMetadata.title].join(' | ')} />
        <Bar />
        <Context.Provider
            value={contextValue}
            children={children}
        />
    </>)
}

const NavigationBar = {
    Provider,
    useSetTitle,
}

export default NavigationBar
