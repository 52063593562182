import { Grid, Typography, useTheme } from '@material-ui/core'
import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'
import { useMaterialClasses } from './hooks'

export default function Bar() {
    const margin = useTheme().spacing(3)
    const { barButtonParent, barButtonChild, itemWithPadding } = useMaterialClasses()

    const { site } = useStaticQuery(graphql`query Bar {
        site {
            siteMetadata {
                title
                navigationBarLinks { title to }
            }
        }
    }`)

    const l = site.siteMetadata.navigationBarLinks as {title: string, to: string}[]

    return (
    <div style={{margin}}>
        <Grid container component='nav' justify='center'>
            <Grid item>
                <Typography
                    variant='button'
                    children={site.siteMetadata.title}
                    className={itemWithPadding}
                />
            </Grid>
            <Grid item>
                {l.map(({title, to}, i) => (
                    <Typography
                        key={i}
                        component={Link}
                        variant='button'
                        to={to}
                        className={barButtonParent}
                    >
                        {title}
                        <Typography
                            variant='button'
                            children={title}
                            color='secondary'
                            className={barButtonChild}
                        />
                    </Typography>
                ))}
            </Grid>
        </Grid>
    </div>)
}