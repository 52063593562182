module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1000,"linkImagesToOriginal":false,"showCaptions":true,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Behrooz Tahanzadeh","short_name":"Behrooz Tahanzadeh","start_url":"/","background_color":"#ffffff","theme_color":"#333333","display":"minimal-ui","icon":"content/assets/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1089b302ecaaea02fd6b5aa3e0eda9df"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
