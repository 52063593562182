import { ThemeProvider } from '@material-ui/core'
import { lightGreen } from '@material-ui/core/colors'
import CssBaseline from '@material-ui/core/CssBaseline'
import React, { ReactNode } from 'react'
import { default as HtmlHeader } from '../html-header/html-header'
import { default as NavigationBar } from '../navigation-bar/navigation-bar'
import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
    palette: {
        background: {
            default: '#fff'
        },
        secondary: {
            main: lightGreen[700]
        }
    },
})

export function wrapPageElement({element}: {element: ReactNode}) {
    return <>
    <HtmlHeader />
    <ThemeProvider theme={theme}>
        <CssBaseline/>
        <NavigationBar.Provider children={element} />
    </ThemeProvider>
    </>
}