import { createStyles, makeStyles } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'

export const useMaterialClasses = makeStyles((theme) => createStyles({
    itemWithPadding: {
        padding: theme.spacing(1),
        lineHeight: 'unset',
    },
    barButtonParent: {
        color: grey[500],
        transition: `color ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeInOut}`,
        whiteSpace:'nowrap',
        textDecoration: 'none',
        position: 'relative',
        lineHeight: 'unset',
        padding: theme.spacing(1),
        
        '&:hover': {
            color: '#fff',
        },
        
        '&:hover>$barButtonChild': {
            clipPath: 'polygon(0 200%, 0 0, 100% 0, 100% 100%)',
        }
    },
    barButtonChild: {
        color: theme.palette.secondary.main,
        whiteSpace: 'nowrap',
        position: 'absolute',
        top: theme.spacing(1),
        left: theme.spacing(1),
        lineHeight: 'unset',
        clipPath: 'polygon(0 0, 0 -200%, 100% -200%, 100% -100%)',
        transition: `clip-path ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}`,
    }
}))
